define("discourse/plugins/chat/discourse/routes/chat-channel-decorator", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withChatChannel;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function withChatChannel(extendedClass) {
    var _class, _descriptor, _descriptor2, _descriptor3;
    return _class = class WithChatChannel extends extendedClass {
      constructor() {
        super(...arguments);
        _initializerDefineProperty(this, "chatChannelsManager", _descriptor, this);
        _initializerDefineProperty(this, "chat", _descriptor2, this);
        _initializerDefineProperty(this, "router", _descriptor3, this);
      }
      async model(params) {
        return this.chatChannelsManager.find(params.channelId);
      }
      titleToken() {
        if (!this.currentModel) {
          return;
        }
        if (this.currentModel.isDirectMessageChannel) {
          return `${this.currentModel.title}`;
        } else {
          return `#${this.currentModel.title}`;
        }
      }
      afterModel(model) {
        super.afterModel?.(...arguments);
        this.chat.activeChannel = model;
        if (!model) {
          return this.router.replaceWith("chat");
        }
        let {
          channelTitle
        } = this.paramsFor(this.routeName);
        if (channelTitle && channelTitle !== model.slugifiedTitle) {
          if (this.routeName === "chat.channel.info") {
            return this.router.replaceWith("chat.channel.info", ...model.routeModels);
          }
          const messageId = this.paramsFor("chat.channel.near-message").messageId;
          const threadId = this.paramsFor("chat.channel.thread").threadId;
          if (threadId) {
            const threadMessageId = this.paramsFor("chat.channel.thread.near-message").messageId;
            if (threadMessageId) {
              this.router.replaceWith("chat.channel.thread.near-message", ...model.routeModels, threadId, threadMessageId);
            } else {
              this.router.replaceWith("chat.channel.thread", ...model.routeModels, threadId);
            }
          } else if (messageId) {
            this.router.replaceWith("chat.channel.near-message", ...model.routeModels, messageId);
          } else {
            this.router.replaceWith("chat.channel", ...model.routeModels);
          }
        } else {
          this.controllerFor("chat-channel").set("targetMessageId", null);
        }
      }
    }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.service], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    })), _class;
  }
});