define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/service", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-channel", "@ember/template-factory", "@ember/component"], function (_exports, _component, _helper, _object, _didInsert, _didUpdate, _service, _navbar, _chatChannel, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _ChatDrawerRoutesChannel;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatDrawerRoutesChannel = _exports.default = (_class = (_ChatDrawerRoutesChannel = class ChatDrawerRoutesChannel extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor2, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor3, this);
    }
    fetchChannel() {
      if (!this.args.params?.channelId) {
        return;
      }
      return this.chatChannelsManager.find(this.args.params.channelId).then(channel1 => {
        this.chat.activeChannel = channel1;
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
        <navbar.BackButton />
        <navbar.ChannelTitle @channel={{this.chat.activeChannel}} />
        <navbar.Actions as |a|>
          <a.ThreadsListButton @channel={{this.chat.activeChannel}} />
          <a.ToggleDrawerButton />
          <a.FullPageButton />
          <a.CloseDrawerButton />
        </navbar.Actions>
      </Navbar>
  
      {{#if this.chatStateManager.isDrawerExpanded}}
        <div
          class="chat-drawer-content"
          {{didInsert this.fetchChannel}}
          {{didUpdate this.fetchChannel @params.channelId}}
        >
          {{#if this.chat.activeChannel}}
            {{#each (array this.chat.activeChannel) as |channel|}}
              {{#if channel}}
                <ChatChannel
                  @targetMessageId={{readonly @params.messageId}}
                  @channel={{channel}}
                />
              {{/if}}
            {{/each}}
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "UIpYDQ/F",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"BackButton\"]],null,null,null],[1,\"\\n      \"],[8,[30,1,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,0,[\"chat\",\"activeChannel\"]]]],null],[1,\"\\n      \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"ThreadsListButton\"]],null,[[\"@channel\"],[[30,0,[\"chat\",\"activeChannel\"]]]],null],[1,\"\\n        \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"      \"],[11,0],[24,0,\"chat-drawer-content\"],[4,[32,1],[[30,0,[\"fetchChannel\"]]],null],[4,[32,2],[[30,0,[\"fetchChannel\"]],[30,3,[\"channelId\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\"]],[[[42,[28,[31,2],[[28,[31,2],[[28,[32,3],[[30,0,[\"chat\",\"activeChannel\"]]],null]],null]],null],null,[[[41,[30,4],[[[1,\"              \"],[8,[32,4],null,[[\"@targetMessageId\",\"@channel\"],[[28,[31,3],[[30,3,[\"messageId\"]]],null],[30,4]]],null],[1,\"\\n\"]],[]],null]],[4]],null]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"navbar\",\"a\",\"@params\",\"channel\"],false,[\"if\",\"each\",\"-track-array\",\"readonly\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel.js",
    "scope": () => [_navbar.default, _didInsert.default, _didUpdate.default, _helper.array, _chatChannel.default],
    "isStrictMode": true
  }), _ChatDrawerRoutesChannel), _ChatDrawerRoutesChannel), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchChannel"), _class.prototype)), _class);
});