define("discourse/plugins/chat/discourse/lib/chat-channel-thread-subscription-manager", ["exports", "@glimmer/tracking", "@ember/application", "@ember/service", "discourse-common/lib/object", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/models/chat-message"], function (_exports, _tracking, _application, _service, _object, _decorators, _chatMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatChannelThreadSubscriptionManager = _exports.default = (_class = class ChatChannelThreadSubscriptionManager {
    constructor(context, thread) {
      let {
        onNewMessage
      } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "messageBus", _descriptor2, this);
      _initializerDefineProperty(this, "channel", _descriptor3, this);
      (0, _application.setOwner)(this, (0, _application.getOwner)(context));
      this.thread = thread;
      this.onNewMessage = onNewMessage;
      this.messageBus.subscribe(this.messageBusChannel, this.onMessage, this.thread.channelMessageBusLastId);
    }
    get messagesManager() {
      return this.thread.messagesManager;
    }
    get messageBusChannel() {
      return `/chat/${this.thread.channel.id}/thread/${this.thread.id}`;
    }
    teardown() {
      this.messageBus.unsubscribe(this.messageBusChannel, this.onMessage);
    }
    onMessage(busData, _, __, lastMessageBusId) {
      switch (busData.type) {
        case "sent":
          this.handleSentMessage(busData);
          break;
        case "reaction":
          this.handleReactionMessage(busData);
          break;
        case "processed":
          this.handleProcessedMessage(busData);
          break;
        case "edit":
          this.handleEditMessage(busData);
          break;
        case "refresh":
          this.handleRefreshMessage(busData);
          break;
        case "delete":
          this.handleDeleteMessage(busData);
          break;
        case "bulk_delete":
          this.handleBulkDeleteMessage(busData);
          break;
        case "restore":
          this.handleRestoreMessage(busData);
          break;
        case "self_flagged":
          this.handleSelfFlaggedMessage(busData);
          break;
        case "flag":
          this.handleFlaggedMessage(busData);
          break;
        case "thread_created":
          this.handleNewThreadCreated(busData);
          break;
      }
      this.thread.threadMessageBusLastId = lastMessageBusId;
    }
    handleSentMessage(data) {
      if (data.chat_message.user.id === this.currentUser.id && data.staged_id) {
        const stagedMessage = this.handleStagedMessage(this.thread.channel, this.messagesManager, data);
        if (stagedMessage) {
          return;
        }
      }
      const message = _chatMessage.default.create(this.thread.channel, data.chat_message);
      message.thread = this.thread;
      message.manager = this.messagesManager;
      this.onNewMessage?.(message);
    }
    handleStagedMessage(channel, messagesManager, data) {
      const stagedMessage = messagesManager.findStagedMessage(data.staged_id);
      if (!stagedMessage) {
        return;
      }
      stagedMessage.error = null;
      stagedMessage.id = data.chat_message.id;
      stagedMessage.staged = false;
      stagedMessage.excerpt = data.chat_message.excerpt;
      stagedMessage.channel = channel;
      stagedMessage.createdAt = new Date(data.chat_message.created_at);
      return stagedMessage;
    }
    handleProcessedMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message.id);
      if (message) {
        message.cooked = data.chat_message.cooked;
        message.uploads = (0, _object.cloneJSON)(data.chat_message.uploads || []);
        message.processed = true;
        message.incrementVersion();
      }
    }
    handleReactionMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message_id);
      if (message) {
        message.react(data.emoji, data.action, data.user, this.currentUser.id);
      }
    }
    handleEditMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message.id);
      if (message) {
        message.excerpt = data.chat_message.excerpt;
        message.uploads = (0, _object.cloneJSON)(data.chat_message.uploads || []);
        message.edited = data.chat_message.edited;
        message.streaming = data.chat_message.streaming;
      }
    }
    handleRefreshMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message.id);
      if (message) {
        message.incrementVersion();
      }
    }
    handleBulkDeleteMessage(data) {
      data.deleted_ids.forEach(deletedId => {
        this.handleDeleteMessage({
          deleted_id: deletedId,
          deleted_at: data.deleted_at
        });
      });
    }
    handleDeleteMessage(data) {
      const deletedId = data.deleted_id;
      const targetMsg = this.messagesManager.findMessage(deletedId);
      if (!targetMsg) {
        return;
      }
      if (this.currentUser.staff || this.currentUser.id === targetMsg.user.id) {
        targetMsg.deletedAt = data.deleted_at;
        targetMsg.deletedById = data.deleted_by_id;
        targetMsg.expanded = false;
      } else {
        this.messagesManager.removeMessage(targetMsg);
      }
      if (this.thread.currentUserMembership?.lastReadMessageId === targetMsg.id) {
        this.thread.currentUserMembership.lastReadMessageId = data.latest_not_deleted_message_id;
      }
    }
    handleRestoreMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message.id);
      if (message) {
        message.deletedAt = null;
      } else {
        const newMessage = _chatMessage.default.create(this.model, data.chat_message);
        newMessage.manager = this.messagesManager;
        this.messagesManager.addMessages([newMessage]);
      }
    }
    handleSelfFlaggedMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message_id);
      if (message) {
        message.userFlagStatus = data.user_flag_status;
      }
    }
    handleFlaggedMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message_id);
      if (message) {
        message.reviewableId = data.reviewable_id;
      }
    }
    handleNewThreadCreated(data) {
      this.thread.threadsManager.find(this.thread.id, data.thread_id, {
        fetchIfNotFound: true
      }).then(thread => {
        const channelOriginalMessage = this.thread.messagesManager.findMessage(thread.originalMessage.id);
        if (channelOriginalMessage) {
          channelOriginalMessage.thread = thread;
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "channel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onMessage"), _class.prototype)), _class);
});