define("discourse/plugins/chat/discourse/components/chat-user-display-name", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/concat-class", "discourse/lib/utilities", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _concatClass, _utilities, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatUserDisplayName;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatUserDisplayName = _exports.default = (_class = (_ChatUserDisplayName = class ChatUserDisplayName extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    get shouldPrioritizeNameInUx() {
      return !this.siteSettings.prioritize_username_in_ux;
    }
    get hasValidName() {
      return this.args.user?.name && this.args.user.name.trim().length > 0;
    }
    get formattedUsername() {
      return (0, _utilities.formatUsername)(this.args.user?.username);
    }
    get shouldShowNameFirst() {
      return this.shouldPrioritizeNameInUx && this.hasValidName;
    }
    get shouldShowNameLast() {
      return !this.shouldPrioritizeNameInUx && this.hasValidName;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <span class="chat-user-display-name">
        {{#if this.shouldShowNameFirst}}
          <span class="chat-user-display-name__name -first">{{@user.name}}</span>
        {{/if}}
  
        <span
          class={{concatClass
            "chat-user-display-name__username"
            (unless this.shouldShowNameFirst "-first")
          }}
        >
          {{this.formattedUsername}}
        </span>
  
        {{#if this.shouldShowNameLast}}
          <span class="chat-user-display-name__name">{{@user.name}}</span>
        {{/if}}
      </span>
    
  */
  {
    "id": "VcFbgqwq",
    "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"chat-user-display-name\"],[12],[1,\"\\n\"],[41,[30,0,[\"shouldShowNameFirst\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-user-display-name__name -first\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,1],[15,0,[28,[32,0],[\"chat-user-display-name__username\",[52,[51,[30,0,[\"shouldShowNameFirst\"]]],\"-first\"]],null]],[12],[1,\"\\n        \"],[1,[30,0,[\"formattedUsername\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"shouldShowNameLast\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-user-display-name__name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@user\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-user-display-name.js",
    "scope": () => [_concatClass.default],
    "isStrictMode": true
  }), _ChatUserDisplayName), _ChatUserDisplayName), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});