define("discourse/plugins/chat/discourse/components/chat/routes/channel-thread", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "discourse/plugins/chat/discourse/components/chat/thread/header", "discourse/plugins/chat/discourse/components/chat-thread", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _helper, _object, _header, _chatThread, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatRoutesChannelThread;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatRoutesChannelThread = _exports.default = (_class = (_ChatRoutesChannelThread = class ChatRoutesChannelThread extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "showfullTitle", _descriptor, this);
    }
    setFullTitle(value1) {
      this.showfullTitle = value1;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-routes-channel-thread">
        {{#each (array @thread) as |thread|}}
          <ThreadHeader
            @thread={{thread}}
            @showFullTitle={{this.showfullTitle}}
          />
  
          <Thread
            @thread={{thread}}
            @targetMessageId={{@targetMessageId}}
            @includeHeader={{true}}
            @setFullTitle={{this.setFullTitle}}
          />
        {{/each}}
      </div>
    
  */
  {
    "id": "JiE1xX8c",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes-channel-thread\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[28,[32,0],[[30,1]],null]],null]],null],null,[[[1,\"        \"],[8,[32,1],null,[[\"@thread\",\"@showFullTitle\"],[[30,2],[30,0,[\"showfullTitle\"]]]],null],[1,\"\\n\\n        \"],[8,[32,2],null,[[\"@thread\",\"@targetMessageId\",\"@includeHeader\",\"@setFullTitle\"],[[30,2],[30,3],true,[30,0,[\"setFullTitle\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@thread\",\"thread\",\"@targetMessageId\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-thread.js",
    "scope": () => [_helper.array, _header.default, _chatThread.default],
    "isStrictMode": true
  }), _ChatRoutesChannelThread), _ChatRoutesChannelThread), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showfullTitle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setFullTitle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setFullTitle"), _class.prototype)), _class);
});