define("discourse/plugins/chat/discourse/controllers/admin-plugins-chat", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n"], function (_exports, _controller, _object, _computed, _service, _ajax, _ajaxError, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdminPluginsChatController = _exports.default = (_dec = (0, _computed.and)("newWebhookName", "newWebhookChannelId"), _dec2 = (0, _object.computed)("model.incoming_chat_webhooks.@each.updated_at"), _dec3 = (0, _object.computed)("selectedWebhookId"), _dec4 = (0, _object.computed)("selectedWebhook.name", "selectedWebhook.chat_channel.id"), (_class = class AdminPluginsChatController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
      _defineProperty(this, "queryParams", [{
        selectedWebhookId: "id"
      }]);
      _defineProperty(this, "loading", false);
      _defineProperty(this, "creatingNew", false);
      _defineProperty(this, "newWebhookName", "");
      _defineProperty(this, "newWebhookChannelId", null);
      _defineProperty(this, "emojiPickerIsActive", false);
      _initializerDefineProperty(this, "nameAndChannelValid", _descriptor2, this);
    }
    get sortedWebhooks() {
      return this.model.incoming_chat_webhooks?.sortBy("updated_at").reverse() || [];
    }
    get selectedWebhook() {
      if (!this.selectedWebhookId) {
        return;
      }
      const id = parseInt(this.selectedWebhookId, 10);
      return this.model.incoming_chat_webhooks.findBy("id", id);
    }
    get saveEditDisabled() {
      return !this.selectedWebhook.name || !this.selectedWebhook.chat_channel.id;
    }
    createNewWebhook() {
      if (this.loading) {
        return;
      }
      this.set("loading", true);
      const data = {
        name: this.newWebhookName,
        chat_channel_id: this.newWebhookChannelId
      };
      return (0, _ajax.ajax)("/admin/plugins/chat/hooks", {
        data,
        type: "POST"
      }).then(webhook => {
        const newWebhook = _object.default.create(webhook);
        this.set("model.incoming_chat_webhooks", [newWebhook].concat(this.model.incoming_chat_webhooks));
        this.resetNewWebhook();
        this.setProperties({
          loading: false,
          selectedWebhookId: newWebhook.id
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    resetNewWebhook() {
      this.setProperties({
        creatingNew: false,
        newWebhookName: "",
        newWebhookChannelId: null
      });
    }
    destroyWebhook(webhook) {
      this.dialog.deleteConfirm({
        message: _discourseI18n.default.t("chat.incoming_webhooks.confirm_destroy"),
        didConfirm: () => {
          this.set("loading", true);
          return (0, _ajax.ajax)(`/admin/plugins/chat/hooks/${webhook.id}`, {
            type: "DELETE"
          }).then(() => {
            this.model.incoming_chat_webhooks.removeObject(webhook);
            this.set("loading", false);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    emojiSelected(emoji) {
      this.selectedWebhook.set("emoji", `:${emoji}:`);
      return this.set("emojiPickerIsActive", false);
    }
    saveEdit() {
      this.set("loading", true);
      const data = {
        name: this.selectedWebhook.name,
        chat_channel_id: this.selectedWebhook.chat_channel.id,
        description: this.selectedWebhook.description,
        emoji: this.selectedWebhook.emoji,
        username: this.selectedWebhook.username
      };
      return (0, _ajax.ajax)(`/admin/plugins/chat/hooks/${this.selectedWebhook.id}`, {
        data,
        type: "PUT"
      }).then(() => {
        this.selectedWebhook.set("updated_at", new Date());
        this.setProperties({
          loading: false,
          selectedWebhookId: null
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    changeChatChannel(chatChannelId) {
      this.selectedWebhook.set("chat_channel", this.model.chat_channels.findBy("id", chatChannelId));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "nameAndChannelValid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortedWebhooks", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "sortedWebhooks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedWebhook", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectedWebhook"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveEditDisabled", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "saveEditDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createNewWebhook", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createNewWebhook"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetNewWebhook", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetNewWebhook"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyWebhook", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "destroyWebhook"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emojiSelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "emojiSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeChatChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeChatChannel"), _class.prototype)), _class));
});