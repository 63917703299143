define("discourse/plugins/chat/discourse/models/chat-chatable", ["exports", "@glimmer/tracking", "@ember/service", "discourse/models/category", "discourse/models/group", "discourse/models/user", "discourse/plugins/chat/discourse/models/chat-channel"], function (_exports, _tracking, _service, _category, _group, _user, _chatChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatChatable = _exports.default = (_class = class ChatChatable {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new ChatChatable(args);
    }
    static createUser(model) {
      return new ChatChatable({
        type: "user",
        model,
        identifier: `u-${model.id}`
      });
    }
    static createGroup(model) {
      return new ChatChatable({
        type: "group",
        model,
        identifier: `g-${model.id}`
      });
    }
    static createChannel(model) {
      return new ChatChatable({
        type: "channel",
        model,
        identifier: `c-${model.id}`
      });
    }
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor, this);
      _initializerDefineProperty(this, "identifier", _descriptor2, this);
      _initializerDefineProperty(this, "type", _descriptor3, this);
      _initializerDefineProperty(this, "model", _descriptor4, this);
      _initializerDefineProperty(this, "enabled", _descriptor5, this);
      _initializerDefineProperty(this, "tracking", _descriptor6, this);
      this.identifier = args.identifier;
      this.type = args.type;
      switch (this.type) {
        case "channel":
          if (args.model.chatable?.users?.length === 1) {
            this.enabled = args.model.chatable?.users[0].has_chat_enabled;
          }
          if (args.model instanceof _chatChannel.default) {
            this.model = args.model;
            break;
          }
          this.model = _chatChannel.default.create(args.model);
          break;
        case "user":
          this.enabled = args.model.has_chat_enabled;
          if (args.model instanceof _user.default) {
            this.model = args.model;
            break;
          }
          this.model = _user.default.create(args.model);
          break;
        case "group":
          this.enabled = args.model.can_chat;
          if (args.model instanceof _group.default) {
            this.model = args.model;
            break;
          }
          this.model = _group.default.create(args.model);
          break;
      }
    }
    get isUser() {
      return this.type === "user";
    }
    get isGroup() {
      return this.type === "group";
    }
    get isCategory() {
      return this instanceof _category.default;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "identifier", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "enabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tracking", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});