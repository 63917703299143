define("discourse/plugins/chat/discourse/components/chat/routes/channels", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/channels-list-public", "discourse/plugins/chat/discourse/components/chat/navbar", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _i18n, _channelsListPublic, _navbar, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatRoutesChannels;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatRoutesChannels = _exports.default = (_class = (_ChatRoutesChannels = class ChatRoutesChannels extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-routes-channels">
        <Navbar as |navbar|>
          <navbar.Title @title={{i18n "chat.chat_channels"}} />
          <navbar.Actions as |action|>
            <action.BrowseChannelsButton />
          </navbar.Actions>
        </Navbar>
  
        <ChannelsListPublic />
      </div>
    
  */
  {
    "id": "cVTVDHby",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes-channels\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"Title\"]],null,[[\"@title\"],[[28,[32,1],[\"chat.chat_channels\"],null]]],null],[1,\"\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"BrowseChannelsButton\"]],null,null,null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"action\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channels.js",
    "scope": () => [_navbar.default, _i18n.default, _channelsListPublic.default],
    "isStrictMode": true
  }), _ChatRoutesChannels), _ChatRoutesChannels), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});