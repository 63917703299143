define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-threads", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/helpers/replace-emoji", "discourse/lib/ajax-error", "discourse-common/helpers/html-safe", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-thread-list", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _didInsert, _service, _replaceEmoji, _ajaxError, _htmlSafe, _discourseI18n, _navbar, _chatThreadList, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatDrawerRoutesChannelThreads;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatDrawerRoutesChannelThreads = _exports.default = (_class = (_ChatDrawerRoutesChannelThreads = class ChatDrawerRoutesChannelThreads extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor2, this);
      _defineProperty(this, "backLinkTitle", _discourseI18n.default.t("chat.return_to_list"));
    }
    get title() {
      return (0, _htmlSafe.default)(_discourseI18n.default.t("chat.threads.list") + " - " + (0, _replaceEmoji.default)(this.chat.activeChannel.title));
    }
    async fetchChannel() {
      if (!this.args.params?.channelId) {
        return;
      }
      try {
        const channel1 = await this.chatChannelsManager.find(this.args.params.channelId);
        this.chat.activeChannel = channel1;
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.chat.activeChannel}}
        <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
          <navbar.BackButton
            @title={{this.backLinkTitle}}
            @route="chat.channel"
            @routeModels={{this.chat.activeChannel.routeModels}}
          />
          <navbar.Title @title={{this.title}} @icon="discourse-threads" />
          <navbar.Actions as |a|>
            <a.ToggleDrawerButton />
            <a.FullPageButton />
            <a.CloseDrawerButton />
          </navbar.Actions>
        </Navbar>
      {{/if}}
  
      <div class="chat-drawer-content" {{didInsert this.fetchChannel}}>
        {{#if this.chat.activeChannel}}
          <ChatThreadList
            @channel={{this.chat.activeChannel}}
            @includeHeader={{false}}
          />
        {{/if}}
      </div>
    
  */
  {
    "id": "ta3NyhGz",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"BackButton\"]],null,[[\"@title\",\"@route\",\"@routeModels\"],[[30,0,[\"backLinkTitle\"]],\"chat.channel\",[30,0,[\"chat\",\"activeChannel\",\"routeModels\"]]]],null],[1,\"\\n        \"],[8,[30,1,[\"Title\"]],null,[[\"@title\",\"@icon\"],[[30,0,[\"title\"]],\"discourse-threads\"]],null],[1,\"\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[11,0],[24,0,\"chat-drawer-content\"],[4,[32,1],[[30,0,[\"fetchChannel\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@channel\",\"@includeHeader\"],[[30,0,[\"chat\",\"activeChannel\"]],false]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"a\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-threads.js",
    "scope": () => [_navbar.default, _didInsert.default, _chatThreadList.default],
    "isStrictMode": true
  }), _ChatDrawerRoutesChannelThreads), _ChatDrawerRoutesChannelThreads), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchChannel"), _class.prototype)), _class);
});