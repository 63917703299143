define("discourse/plugins/chat/discourse/lib/textarea-interactor", ["exports", "@ember/application", "@ember/destroyable", "@ember/object", "@ember/runloop", "@ember/service", "discourse/mixins/textarea-text-manipulation"], function (_exports, _application, _destroyable, _object, _runloop, _service, _textareaTextManipulation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  // This class sole purpose is to provide a way to interact with the textarea
  // using the existing TextareaTextManipulation mixin without using it directly
  // in the composer component. It will make future migration easier.
  let TextareaInteractor = _exports.default = (_class = class TextareaInteractor extends _object.default.extend(_textareaTextManipulation.default) {
    constructor(owner, textarea) {
      super(...arguments);
      _initializerDefineProperty(this, "capabilities", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
      (0, _application.setOwner)(this, owner);
      this.textarea = textarea;
      this._textarea = textarea;
      this.element = this._textarea;
      this.ready = true;
      this.composerFocusSelector = `#${textarea.id}`;
      this.init(); // mixin init wouldn't be called otherwise
      this.composerEventPrefix = null; // we don't need app events

      // paste is using old native ember events defined on composer
      this.textarea.addEventListener("paste", this.paste);
      (0, _destroyable.registerDestructor)(this, instance => instance.teardown());
    }
    teardown() {
      this.textarea.removeEventListener("paste", this.paste);
    }
    set value(value) {
      this._textarea.value = value;
      const event = new Event("input", {
        bubbles: true,
        cancelable: true
      });
      this._textarea.dispatchEvent(event);
    }
    blur() {
      (0, _runloop.next)(() => {
        (0, _runloop.schedule)("afterRender", () => {
          this._textarea.blur();
        });
      });
    }
    focus() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        ensureAtEnd: false,
        refreshHeight: true,
        addText: null
      };
      (0, _runloop.next)(() => {
        (0, _runloop.schedule)("afterRender", () => {
          if (opts.refreshHeight) {
            this.refreshHeight();
          }
          if (opts.ensureAtEnd) {
            this.ensureCaretAtEnd();
          }
          if (this.capabilities.isIpadOS || this.site.mobileView) {
            return;
          }
          if (opts.addText) {
            this.addText(this.getSelected(), opts.addText);
          }
          this.focusTextArea();
        });
      });
    }
    ensureCaretAtEnd() {
      (0, _runloop.schedule)("afterRender", () => {
        this._textarea.setSelectionRange(this._textarea.value.length, this._textarea.value.length);
      });
    }
    refreshHeight() {
      (0, _runloop.schedule)("afterRender", () => {
        // this is a quirk which forces us to `auto` first or textarea
        // won't resize
        this._textarea.style.height = "auto";

        // +1 is to workaround a rounding error visible on electron
        // causing scrollbars to show when they shouldn’t
        this._textarea.style.height = this._textarea.scrollHeight + 1 + "px";
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "capabilities", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});