define("discourse/plugins/chat/discourse/components/chat/composer/thread", ["exports", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/components/chat-composer"], function (_exports, _object, _service, _decorators, _discourseI18n, _chatMessage, _chatComposer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatComposerThread = _exports.default = (_dec = (0, _service.service)("chat-channel-composer"), _dec2 = (0, _service.service)("chat-thread-composer"), _dec3 = (0, _service.service)("chat-thread-pane"), _dec4 = (0, _decorators.debounce)(2000), (_class = class ChatComposerThread extends _chatComposer.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "channelComposer", _descriptor, this);
      _initializerDefineProperty(this, "composer", _descriptor2, this);
      _initializerDefineProperty(this, "pane", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "chatDraftsManager", _descriptor5, this);
      _defineProperty(this, "context", "thread");
      _defineProperty(this, "composerId", "thread-composer");
    }
    persistDraft() {
      this.chatDraftsManager.add(this.draft, this.args.thread.channel.id, this.args.thread.id);
    }
    destroyDraft() {
      this.chatDraftsManager.remove(this.args.thread.channel.id, this.args.thread.id);
    }
    resetDraft() {
      this.args.thread.resetDraft(this.currentUser);
    }
    get draft() {
      return this.args.thread.draft;
    }
    get disabled() {
      return !this.chat.userCanInteractWithChat || !this.args.thread.channel.canModifyMessages(this.currentUser);
    }
    get presenceChannelName() {
      const thread = this.args.thread;
      return `/chat-reply/${thread.channel.id}/thread/${thread.id}`;
    }
    get placeholder() {
      return _discourseI18n.default.t("chat.placeholder_thread");
    }
    lastUserMessage(user) {
      return this.args.thread.messagesManager.findLastUserMessage(user);
    }
    handleEscape(event) {
      if (this.draft.editing) {
        event.stopPropagation();
        this.args.thread.draft = _chatMessage.default.createDraftMessage(this.args.thread.channel, {
          user: this.currentUser,
          thread: this.args.thread
        });
        return;
      }
      if (this.isFocused) {
        event.stopPropagation();
        this.composer.blur();
      } else {
        this.pane.close().then(() => {
          this.channelComposer.focus();
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channelComposer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "composer", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pane", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "chatDraftsManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "persistDraft", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "persistDraft"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyDraft", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "destroyDraft"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetDraft", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetDraft"), _class.prototype)), _class));
});