define("discourse/plugins/chat/discourse/routes/chat-channel-info", ["exports", "@ember/service", "discourse/routes/discourse", "discourse/plugins/chat/discourse/services/chat-channel-info-route-origin-manager", "discourse/plugins/chat/discourse/routes/chat-channel-decorator"], function (_exports, _service, _discourse, _chatChannelInfoRouteOriginManager, _chatChannelDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatChannelInfoRoute = _exports.default = (0, _chatChannelDecorator.default)(_class = (_class2 = class ChatChannelInfoRoute extends _discourse.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatChannelInfoRouteOriginManager", _descriptor, this);
    }
    activate(transition) {
      const name = transition?.from?.name;
      if (name) {
        this.chatChannelInfoRouteOriginManager.origin = name.startsWith("chat.browse") ? _chatChannelInfoRouteOriginManager.ORIGINS.browse : _chatChannelInfoRouteOriginManager.ORIGINS.channel;
      }
    }
    deactivate() {
      this.chatChannelInfoRouteOriginManager.origin = null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "chatChannelInfoRouteOriginManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class;
});