define("discourse/plugins/chat/discourse/components/chat-user-info", ["exports", "@glimmer/component", "ember-modifier", "discourse/components/user-status-message", "discourse/lib/url", "discourse/plugins/chat/discourse/components/chat-user-avatar", "discourse/plugins/chat/discourse/components/chat-user-display-name", "@ember/template-factory", "@ember/component"], function (_exports, _component, _emberModifier, _userStatusMessage, _url, _chatUserAvatar, _chatUserDisplayName, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _ChatUserInfo;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ChatUserInfo extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "trackUserStatus", (0, _emberModifier.modifier)((element1, _ref) => {
        let [user1] = _ref;
        user1.statusManager.trackStatus();
        return () => {
          user1.statusManager.stopTrackingStatus();
        };
      }));
    }
    get avatarSize() {
      return this.args.avatarSize ?? "medium";
    }
    get userPath() {
      return (0, _url.userPath)(this.args.user.username);
    }
    get interactive() {
      return this.args.interactive ?? false;
    }
    get showStatus() {
      return this.args.showStatus ?? false;
    }
    get showStatusDescription() {
      return this.args.showStatusDescription ?? false;
    }
  }
  _exports.default = ChatUserInfo;
  _ChatUserInfo = ChatUserInfo;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @user}}
        <ChatUserAvatar
          @user={{@user}}
          @avatarSize={{this.avatarSize}}
          @interactive={{this.interactive}}
        />
  
        {{#if this.interactive}}
          <a href={{this.userPath}} data-user-card={{@user.username}}>
            <ChatUserDisplayName @user={{@user}} />
          </a>
        {{else}}
          <ChatUserDisplayName @user={{@user}} />
        {{/if}}
  
        {{#if this.showStatus}}
          <div class="user-status" {{this.trackUserStatus @user}}>
            <UserStatusMessage
              @status={{@user.status}}
              @showDescription={{this.showStatusDescription}}
            />
          </div>
        {{/if}}
      {{/if}}
    
  */
  {
    "id": "xZaYb+tP",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[32,0],null,[[\"@user\",\"@avatarSize\",\"@interactive\"],[[30,1],[30,0,[\"avatarSize\"]],[30,0,[\"interactive\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"interactive\"]],[[[1,\"        \"],[10,3],[15,6,[30,0,[\"userPath\"]]],[15,\"data-user-card\",[30,1,[\"username\"]]],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[32,1],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"showStatus\"]],[[[1,\"        \"],[11,0],[24,0,\"user-status\"],[4,[30,0,[\"trackUserStatus\"]],[[30,1]],null],[12],[1,\"\\n          \"],[8,[32,2],null,[[\"@status\",\"@showDescription\"],[[30,1,[\"status\"]],[30,0,[\"showStatusDescription\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"]],[\"@user\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-user-info.js",
    "scope": () => [_chatUserAvatar.default, _chatUserDisplayName.default, _userStatusMessage.default],
    "isStrictMode": true
  }), _ChatUserInfo);
});